import { ConfigProvider, Table } from "antd";

function BasicTable({dataSource,columns,pagination=false}){
    return(
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        borderColor:'#1B191C',
                        headerColor:'white',
                        headerBorderRadius:0
                    },
                },
                token: {
                    colorBgContainer:'#1B191C',
                    colorText:'white',
                    borderRadius:0
                },
          }}>
            <Table 
                dataSource={dataSource} 
                columns={columns}
                size="small"
                className="rounded-lg"
                style={{border:'1px solid rgba(0,0,0,0.1)'}}
                scroll={{
                    x: '100%',
                }}              
                pagination={pagination} />
        </ConfigProvider>
        
    )
}

export default BasicTable;