import { CloseOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import FilterServices from "../../utils/FilterServices";
import LocationServices from "../../utils/LocationServices";
import { FaShuffle } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "../../utils/useLocalStorage";

function FilterDrawer({isFilterDrawerVisible,setIsFilterDrawerVisible}) {
    const [searchParams,setSearchParams] = useSearchParams();
    const [stages,setStages] = useState(null);
    const [stageCount,setStageCount] = useState(null);
    const [types,setTypes] = useState(null);
    const [typeCount,setTypeCount] = useState(null);
    const [industries,setIndustries] = useState(null);
    const [industryCount,setIndustryCount] = useState(null);
    const [locations,setLocations] = useState(null);
    const [locationCount,setLocationCount] = useState(null);

    const getStages = async (page) => {
        const stagesResponse = await FilterServices.getStages({page:page});
        setStages(stagesResponse.results);
        setStageCount(stagesResponse.count);
    }
    const getTypes = async (page) => {
        const typesResponse = await FilterServices.getTypes({page:page});
        setTypes(typesResponse.results);
        setTypeCount(typesResponse.count);
    }
    const getIndustries = async (page) => {
        const industriesResponse = await FilterServices.getIndustries({page:page});
        setIndustries(industriesResponse.results);
        setIndustryCount(industriesResponse.count);
    }
    const getLocations = async (page) => {
        const locationsResponse = await LocationServices.getCountries({page:page});
        setLocations(locationsResponse.results);
        setLocationCount(locationsResponse.count);
    }
    const clickShuffle = (value) => {
        if(value == 'stage'){
            const stageVals = Math.ceil(stageCount / 15);
            getStages(Math.floor(Math.random() * (stageVals + 1)));
        }
        else if(value == 'type'){
            const typeVals = Math.ceil(typeCount / 15);
            getTypes(Math.floor(Math.random() * (typeVals + 1)));
        }
        else if(value == 'industry'){
            const industryVals = Math.ceil(industryCount / 15);
            getIndustries(Math.floor(Math.random() * (industryVals + 1)));            
        }
        else if(value == 'country'){
            const locationVals = Math.ceil(locationCount / 15);
            getLocations(Math.floor(Math.random() * (locationVals + 1)));            
        }
    }

    const addFilter = async (type,value) => {
        if(searchParams.has(type)){
            if(!searchParams.get(type).split(',').includes(value)){
                let filters = searchParams.get(type).split(',').filter(x => {return x != ''});
                searchParams.set(type,[...filters,value].join(','));
                searchParams.set('page',0);
                setSearchParams(searchParams);
            }
        }
        else{
            searchParams.set(type,value);
            searchParams.set('page',0);
            setSearchParams(searchParams);
        }
    }
    const removeFilter = async (type,value) => {
        if(searchParams.has(type)){
            if(searchParams.get(type).split(',').includes(value)){
                let filters = searchParams.get(type).split(',').filter(x => {return x != ''}).filter(x => {return x != value});
                if(filters.length > 0){
                    searchParams.set(type,filters.join(','));
                    setSearchParams(searchParams);
                }
                else{
                    searchParams.delete(type);
                    setSearchParams(searchParams);
                }
            }
        }
    }

    const getFilters = async () => {
        await getStages(0);
        await getTypes(0);
        await getIndustries(0);
        await getLocations(0);
    }

    useEffect(() => {
        getFilters();
    },[])


    return(
        <Drawer 
            width={window.innerWidth > 1000 ? '30%' : window.innerWidth > 800 ? '50%' : '100%'}
            open={isFilterDrawerVisible}
            onClose={() => setIsFilterDrawerVisible(false)}
            closeIcon={false}
            placement="right"
            styles={{'body':{padding:0,backgroundColor:'black',color:'white'}}}
            >
            <div className="flex flex-col w-full h-full px-4 overflow-y-scroll">
                <div className="flex flex-col gap-5 pb-24">
                    <div className="bg-black border-b p-4 shadow-lg flex items-center sticky top-0">
                        <div className="flex-1">
                            <h1>Add Filters</h1>
                        </div>
                        <div className="block text-2xl">
                            <div className="rounded-xl bg-[#1B191C] px-3 aspect-square flex items-center justify-center cursor-pointer" onClick={() => setIsFilterDrawerVisible(false)}>
                                <CloseOutlined/>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div>
                            <div className="mb-6 flex items-center">
                                <div className="flex-1">
                                    <h2 className="m-0 leading-tight">Stages</h2>                                    
                                </div>
                                <div>
                                    <div onClick={() => clickShuffle('stage')} className="px-2 py-1 bg-[#1B191C] rounded-md text-white flex items-center gap-1 cursor-pointer hover:font-bold">
                                        <FaShuffle/> Shuffle
                                    </div>
                                </div>
                            </div>
                            {
                                searchParams.has('stage') &&
                                    <div className="w-full flex flex-wrap gap-1 items-center mb-2">
                                        {
                                            searchParams.has('stage') &&
                                                searchParams.get('stage').split(',').map((value,index) =>
                                                    <div key={`stage-exist-${index}`} onClick={() => removeFilter('stage',value)} className="px-2 py-1 bg-[#1B191C] rounded-md cursor-pointer">
                                                        {value} <span className="text-[#FF0000] hover:font-bold"><CloseOutlined /></span>
                                                    </div>                                    
                                                )
                                        }
                                    </div>
                            }
                            <div className="w-full flex flex-wrap gap-2 items-center">
                                {
                                    stages && stages.length > 0 &&
                                        stages.filter(x => {return searchParams.has('stage') ? !searchParams.get('stage').split(',').includes(x.name) : true}).map((value,index) => 
                                            <div key={`stage-${index}`} onClick={() => addFilter('stage',value.name)} className="px-2 py-1 bg-[#1B191C] rounded-md text-white cursor-pointer hover:font-bold">
                                                {value.name}
                                            </div>
                                        )
                                }
                                + {stageCount - 15} stages
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div>
                            <div className="mb-6 flex items-center">
                                <div className="flex-1">
                                    <h2 className="m-0 leading-tight">Types</h2>                                    
                                </div>
                                <div>
                                    <div onClick={() => clickShuffle('type')} className="px-2 py-1 bg-[#1B191C] rounded-md text-white flex items-center gap-1">
                                        <FaShuffle/> Shuffle
                                    </div>
                                </div>
                            </div>
                            {
                                searchParams.has('type') &&
                                    <div className="w-full flex flex-wrap gap-1 items-center mb-2">
                                        {
                                            searchParams.has('type') &&
                                                searchParams.get('type').split(',').map((value,index) =>
                                                    <div key={`type-exist-${index}`} onClick={() => removeFilter('type',value)} className="px-2 py-1 bg-[#1B191C] rounded-md cursor-pointer">
                                                        {value} <span className="text-[#FF0000] hover:font-bold"><CloseOutlined /></span>
                                                    </div>                                    
                                                )
                                        }
                                    </div>
                            }
                            <div className="w-full flex flex-wrap gap-2 items-center">
                                {
                                    types && types.length > 0 &&
                                        types.filter(x => {return (searchParams.has('type') ? !searchParams.get('type').split(',').includes(x.name) : true)}).map((value,index) => 
                                            <div key={`type-${index}`} onClick={() => addFilter('type',value.name)} className="px-2 py-1 bg-[#1B191C] rounded-md text-white cursor-pointer hover:font-bold">
                                                {value.name}
                                            </div>
                                        )
                                }
                                + {typeCount - 15} types
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div>
                            <div className="mb-6 flex items-center">
                                <div className="flex-1">
                                    <h2 className="m-0 leading-tight">Industries</h2>                                    
                                </div>
                                <div>
                                    <div onClick={() => clickShuffle('industry')} className="px-2 py-1 bg-[#1B191C] rounded-md text-white flex items-center gap-1">
                                        <FaShuffle/> Shuffle
                                    </div>
                                </div>
                            </div>
                            {
                                searchParams.has('industry') &&
                                    <div className="w-full flex flex-wrap gap-1 items-center mb-2">
                                        {
                                            searchParams.has('industry') &&
                                                searchParams.get('industry').split(',').map((value,index) =>
                                                    <div key={`industry-exist-${index}`} onClick={() => removeFilter('industry',value)} className="px-2 py-1 bg-[#1B191C] rounded-md cursor-pointer">
                                                        {value} <span className="text-[#FF0000] hover:font-bold"><CloseOutlined /></span>
                                                    </div>                                    
                                                )
                                        }
                                    </div>
                            }
                            <div className="w-full flex flex-wrap gap-2 items-center">
                                {
                                    industries && industries.length > 0 &&
                                        industries.filter(x => {return searchParams.has('industry') ? !searchParams.get('industry').split(',').includes(x.name) : true}).map((value,index) => 
                                            <div key={`industry-${index}`} onClick={() => addFilter('industry',value.name)} className="px-2 py-1 bg-[#1B191C] rounded-md text-white cursor-pointer hover:font-bold">
                                                {value.name}
                                            </div>
                                        )
                                }
                                + {industryCount - 15} industries
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div>
                            <div className="mb-6 flex items-center">
                                <div className="flex-1">
                                    <h2 className="m-0 leading-tight">Locations</h2>                                    
                                </div>
                                <div>
                                    <div onClick={() => clickShuffle('country')} className="px-2 py-1 bg-[#1B191C] rounded-md text-white flex items-center gap-1">
                                        <FaShuffle/> Shuffle
                                    </div>
                                </div>
                            </div>
                            {
                                searchParams.has('country') &&
                                    <div className="w-full flex flex-wrap gap-1 items-center mb-2">
                                        {
                                            searchParams.has('country') &&
                                                searchParams.get('country').split(',').map((value,index) =>
                                                    <div key={`country-exist-${index}`} onClick={() => removeFilter('country',value)} className="px-2 py-1 bg-[#1B191C] rounded-md cursor-pointer">
                                                        {value} <span className="text-[#FF0000] hover:font-bold"><CloseOutlined /></span>
                                                    </div>                                    
                                                )
                                        }
                                    </div>
                            }
                            <div className="w-full flex flex-wrap gap-2 items-center">
                                {
                                    locations && locations.length > 0 &&
                                        locations.filter(x => {return searchParams.has('country') ? !searchParams.get('country').split(',').includes(x.name) : true}).map((value,index) => 
                                            <div key={`country-${index}`} onClick={() => addFilter('country',value.name)} className="px-2 py-1 bg-[#1B191C] rounded-md text-white cursor-pointer hover:font-bold">
                                                {value.name}
                                            </div>
                                        )
                                }
                                + {locationCount - 15} locations
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default FilterDrawer;