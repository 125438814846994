import BasicTable from "./BasicTable";

const experienceColumns = [
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role'
    },
    {
        title: 'Company',
        dataIndex: 'organization',
        key: 'organization',
        render: (org) => (
            <div>{org}</div>
        )
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (date) => (
            <div>{date ? date.split('T')[0] : 'N/A'}</div>
        )
    },

]

function ExperienceTable({data}){
    return(
        <BasicTable dataSource={data.filter(x => x)} columns={experienceColumns}/>
    )
}

export default ExperienceTable;