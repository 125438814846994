import { ConsoleSqlOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useMainContext } from "../../components/Context/MainContext";
import FilterServices from "../../utils/FilterServices";
import LocationServices from "../../utils/LocationServices";


function AllListsPage() {
    const {setSidebarCollapsed} = useMainContext();
    const [linkList,setLinkList] = useState(null);
    const [stageList,setStageList] = useState(null);
    const [industryList,setIndustryList] = useState(null);
    const [locationList,setLocationList] = useState(null);


    const getAllLinks = async () => {
        const stagesResponse = await FilterServices.getStages({page:0});
        const industriesResponse = await FilterServices.getIndustries({page:0});
        const locationsResponse = await LocationServices.getCountries({page:0});

        setStageList(stagesResponse.results);
        setIndustryList(industriesResponse.results);
        setLocationList(locationsResponse.results);
        
        let fullInvestorList = [];
        let fullFundList = [];
        [...stagesResponse.results].forEach((stageValue) => {
            [...industriesResponse.results].forEach((industryValue) => {
                [...locationsResponse.results].forEach((locationValue) => {
                    fullInvestorList.push({
                        'name':`Top${stageValue !== '' && ` ${stageValue.name}`}${industryValue !== '' && ` ${industryValue.name}`} Investors${locationValue !== '' && ` in ${locationValue.name}`}`,
                        'link':`/${stageValue !== '' ? stageValue?.key : 'all'}/investors/${industryValue !== '' ? industryValue?.key : 'all'}/${locationValue !== '' ? locationValue.key : 'all'}`
                    });
                    fullFundList.push({
                        'name':`Top${stageValue !== '' && ` ${stageValue.name}`}${industryValue !== '' && ` ${industryValue.name}`} Funds${locationValue !== '' && ` in ${locationValue.name}`}`,
                        'link':`/${stageValue !== '' ? stageValue?.key : 'all'}/funds/${industryValue !== '' ? industryValue?.key : 'all'}/${locationValue !== '' ? locationValue.key : 'all'}`
                    })
                })
            })
        })
        setLinkList([...fullInvestorList,...fullFundList]);
    }

    useEffect(() => {
        getAllLinks();
    },[])


    return(
        
        <div className="bg-black min-h-screen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`VC Hunt - Full List of All Investor and Fund Data Lists`}</title>
                <meta property="og:title" content={`VC Hunt - Full List of All Investor and Fund Data Lists`}/>
                <meta name="description" content={`VC Hunt - Full List of All Investor and Fund Data Lists`} />
                <link rel="canonical" href="https://www.vc-hunt.com" />
            </Helmet>
            <div className="text-left">
                <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-4 w-full m-auto">
                    <div className="pl-8 py-8 static md:sticky top-0 h-fit">
                        <div className="flex flex-col text-white w-full md:w-11/12 m-auto">
                            <div className="fixed top-0 left-0 right-0 bg-black h-20 p-4 shadow-lg flex items-center z-20 md:relative mb-4 mb-8 flex items-center">
                                <div className="flex-1">
                                    <Link to='/'>
                                        <h1>VC Hunt</h1>
                                    </Link>
                                </div>
                                <div className="block md:hidden text-2xl">
                                    <div className="rounded-xl bg-[#1B191C] px-3 aspect-square flex items-center justify-center" onClick={() => setSidebarCollapsed(true)}>
                                        <MenuFoldOutlined />
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-3 lg:col-span-4 flex grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 p-2 md:p-0 bg-black">
                        <div className="col-span-1 md:col-span-3 flex flex-col items-start text-white w-full md:w-10/12 lg:w-11/12 m-auto h-full">
                            <div className="h-20 md:h-28 flex  items-center mb-4 md:mb-0 bg-black relative md:sticky top-0 z-10 shadow-lg w-full h-auto">
                                <div className="pt-0 md:pt-4 pl-2 md:pl-0 w-10/12">
                                    <h1 className="leading-tight mb-0.5">
                                        All VC Investor and Fund Lists
                                    </h1>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h1>Investor Lists</h1>
                                </div>
                                <div className="w-full grid grid-cols-1 md:grid-cols-3">
                                    <div>
                                        <div className="mb-3">
                                            <h3>Investors By Stages</h3>
                                        </div>
                                        <div>
                                            {
                                                stageList && stageList.length > 0 &&
                                                    stageList.map((value,index) => 
                                                        <div key={`stage-${index}`} className="leading-snug">
                                                            <Link to={`/${value?.key}/investors/all/all`}>
                                                                <div>
                                                                    {value?.name}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ) 
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <h3>Investors By Industry</h3>
                                        </div>
                                        <div>
                                            {
                                                industryList && industryList.length > 0 &&
                                                    industryList.map((value,index) => 
                                                        <div key={`industry-${index}`} className="leading-snug">
                                                            <Link to={`/all/investors/${value?.key}/all`}>
                                                                <div>
                                                                    {value?.name}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ) 
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <h3>Investors By Location</h3>
                                        </div>
                                        <div>
                                            {
                                                locationList && locationList.length > 0 &&
                                                locationList.map((value,index) => 
                                                        <div key={`location-${index}`} className="leading-snug">
                                                            <Link to={`/all/investors/all/${value?.key}`}>
                                                                <div>
                                                                    {value?.name}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ) 
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-col gap-1">
                                {
                                    linkList && linkList.length > 0 &&
                                        linkList.map((value,index) => 
                                            <div key={`link-${index}`} className="leading-snug">
                                                <Link to={value?.link}>
                                                    <div>
                                                        {value?.name}
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllListsPage;