import { Table } from "antd";
import BasicTable from "./BasicTable";

const educationColumns = [
    {
        title: 'Degree',
        dataIndex: 'degree',
        key: 'degree'
    },
    {
        title: 'Organization',
        dataIndex: 'organization',
        key: 'organization',
        render: (organization) => (
            <div>{organization}</div>
        )
    },

]

function EducationTable({data}){
    return(
        <BasicTable dataSource={data.filter(x => x)} columns={educationColumns}/>
    )
}

export default EducationTable;