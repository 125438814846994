import { EnvironmentOutlined, ExportOutlined, HeartOutlined, PlusOutlined } from "@ant-design/icons";
import LinkIconButton from "../../../components/Button/LinkIconButton";
import { useState } from "react";
import { Avatar, Tooltip } from "antd";
import { Link } from "react-router-dom";


function FundPreviewCard({values,onSave=false}){
    const [isHover,setIsHover] = useState(false);

    return(
        <div className="p-4 rounded-2xl bg-[#1B191C] border md:border-0 text-white cursor-pointer h-full shadow-lg" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div className="flex items-start md:items-start gap-3 w-full">
                <div>
                    {
                        values?.image_url
                        ? <div className="w-[60px] md:w-[80px] aspect-square rounded-lg border bg-cover bg-no-repeat bg-center" style={{backgroundImage:`url(${values?.image_url})`}}></div>
                        : <div className="w-[60px] md:w-[80px] aspect-square rounded-xl border bg-black flex items-center justify-center font-bold text-4xl" ><div>{values?.name.split(' ').map(value => value[0]).slice(0,2).join('')}</div></div>
                    }
                </div>
                <div className="flex flex-col gap-1.5 w-full">
                    <div className="text-white flex items-center w-full">
                        <div className="flex items-start flex-1">
                            <Link to={`/fund/${values?.key}`} className="w-full">
                                <div className="flex items-center w-fit">
                                    <h2 className="m-auto leading-none font-semibold w-fit">
                                        {values?.name} {values?.age && `(${values?.age})`}
                                    </h2>
                                    <span className={`ml-2 text-lg transform duration-200 ease-in ${isHover ? 'opacity-100':'opacity-0'}`}>
                                        <ExportOutlined/>
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div className="p-2 px-3 text-base rounded-md w-fit hover:scale-110" style={{backgroundColor:'rgba(0,0,0,0.4)'}} onClick={() => onSave && onSave(values)}>
                            <HeartOutlined />
                        </div>
                    </div>
                    {
                        values?.stages && values?.stages.length > 0 &&
                            <div className="flex flex-wrap gap-1 items-center">
                                {
                                    values?.stages.slice(0,3).map((value,index) =>
                                        <div key={`stage-${index}`} className="text-xs px-2 py-1 rounded-md bg-black">
                                            {value.name}
                                        </div>
                                    )
                                }
                                {
                                    values?.stages.length > 3 &&
                                        <div className="text-xs">
                                            {`${values?.stages.length - 3} more stages`}
                                        </div>
                                }
                            </div>
                    }
                    {
                        values?.descriptions && values?.descriptions.length > 0 &&
                            <Link to={`/fund/${values?.key}`} className="hover:text-white">
                                <div className="w-full text-wrap leading-snug text-base bg-[#111111] p-4 rounded-md mb-1">
                                    <div>
                                        {values?.descriptions[0].slice(0,100)}...
                                    </div>
                                </div>
                            </Link>
                    }
                    {
                        values?.vcinvestor_set && values?.vcinvestor_set.length > 0 &&
                            <div>
                                <Avatar.Group max={{count:3}} size={'large'}>
                                    {
                                        values?.vcinvestor_set.map((value,index) => 
                                            <Tooltip title={value?.name} placement="top" key={`avatar-${index}`}>
                                                <Link to={`/investor/${value?.key}`}>
                                                    {
                                                        value?.image_url
                                                        ? <Avatar src={value?.image_url} size={'large'} />
                                                        : <Avatar size={'large'} className="bg-black" >{values?.name.split(' ').map(value => value[0]).slice(0,2).join('')}</Avatar>
                                                    }
                                                </Link>
                                            </Tooltip>
                                        )
                                    }

                                </Avatar.Group>
                            </div>
                    }
                </div>
            </div>
            <div className="flex items-start gap-0 md:gap-3 w-full">
                <div className="w-0 md:w-[80px]"></div>
                <div className="px-1 w-full">
                    <div className="flex flex-col gap-2 pt-2 pb-4 w-full">
                        {
                            false &&
                                <div className="flex flex-col gap-2 px-2 w-full">
                                    {
                                        values?.industries && values?.industries.length > 0 &&
                                            <div className="flex flex-wrap gap-1 items-center leading-tight">
                                                <div className="fond-semibold">
                                                    Industries:
                                                </div>
                                                {
                                                    values?.industries.slice(0,3).map((value,index) =>
                                                        <div key={`industry-${index}`} className="text-xs px-2 py-1 rounded-md bg-black">
                                                            {value.name}
                                                        </div>
                                                    )
                                                }
                                                {
                                                    values?.industries.length > 3 &&
                                                        <div className="text-xs">
                                                            {`${values?.industries.length - 3} more stages`}
                                                        </div>
                                                }
                                            </div>
                                    }
                                </div>
                        }
                    </div>
                    <div className="pt-2 border-t-2 flex items-center w-full" style={{borderColor:'rgba(255,255,255,0.1)'}}>
                        <div className="flex-1">
                            {
                                values?.hq_location &&
                                    <div className="text-xs w-fit px-2 py-1 rounded-lg" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                                        <EnvironmentOutlined/> {values?.hq_location?.name}
                                    </div>
                            }
                        </div>
                        <div className="flex gap-1">
                            {
                                values?.links && Object.keys(values?.links).length > 0 &&
                                    Object.keys(values?.links).map((value,index) =>
                                        <a href={values?.links?.[value]} target="_blank" className="hover:text-white hover:scale-105">
                                            <div key={`link-${index}`} className="flex items-center text-base cursor-pointer w-fit rounded-lg" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                                                <LinkIconButton type={value} url={values?.links?.[value]} size="base" />
                                            </div>
                                        </a>
                                    )
                            }
                        </div>
                    </div>
                    <div className="pt-3 flex gap-1 items-center w-full block md:hidden">
                        <div className="p-2 text-base text-center w-full rounded-md" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                            <PlusOutlined/> Connect
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FundPreviewCard;