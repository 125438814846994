import { Table } from "antd";
import BasicTable from "./BasicTable";

const investmentColumns = [
    {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        render: (company) => (
            <div>{company?.name ? company?.name : 'N/A'}</div>
        )
    },
    {
        title: 'Stage',
        dataIndex: 'stage',
        key: 'stage',
        render: (stage) => (
            <div>{stage?.name ? stage?.name : 'N/A'}</div>
        )
    },
    {
        title: 'Round Size',
        dataIndex: 'round_size',
        key: 'round_size'
    },
    {
        title: 'Total Raised',
        dataIndex: 'total_raised',
        key: 'total_raised',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (date) => (
            <div>{date ? date.split('T')[0] : 'N/A'}</div>
        )
    },
]

function InvestmentTable({data}){
    return(
        <BasicTable dataSource={data.filter(x => x)} columns={investmentColumns}/>
    )
}

export default InvestmentTable;