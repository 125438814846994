import { Link } from "react-router-dom";
import { useState } from "react";


function InvestorSimpleListCard({values}){
    const [isHover,setIsHover] = useState(false);

    return(
        <div className="text-white cursor-pointer h-full" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div className="flex items-start md:items-center gap-3 w-full mb-2">
                <div className="border rounded-lg">
                    {
                        values?.image_url
                        ? <div className="w-[30px] md:w-[50px] aspect-square rounded-lg border bg-cover bg-no-repeat bg-center" style={{backgroundImage:`url(${values?.image_url})`}}></div>
                        : <div className="w-[30px] md:w-[50px] aspect-square rounded-lg border bg-black flex items-center justify-center font-bold text-2xl" ><div>{values?.name.split(' ').map(value => value[0]).slice(0,2).join('')}</div></div>
                    }
                </div>
                <div className="flex flex-col gap-1.5">
                    <Link to={`/investor/${values.key}`}>
                        <div className="text-white flex items-center w-fit">
                            <h3 className="m-auto leading-none font-semibold w-fit">
                                {values?.name}
                            </h3>
                        </div>
                    </Link>
                    {
                        (values?.role || values?.company) &&
                            <div className="text-sm leading-none">
                                <h4 className="m-auto leading-none mb-0">{values?.role && values?.role.split('\n\n')[0]} {values?.role && values?.company && 'at'} {values?.company && <Link to={`/fund/${values?.company?.key}`} className="underline">{values?.company?.name}</Link>}</h4>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default InvestorSimpleListCard;