import { Avatar } from "antd";
import no_image from '../../images/no_image.webp';


function UserAvatar({user}){
    return(
        <div className="bg-[#1B191C] rounded-lg p-4 w-full flex gap-2 items-center cursor-pointer">
            <div>
                <Avatar src={user?.avatar ? user?.avatar : no_image} size={'large'} />
            </div>
            <div className="font-semibold text-base">
                {user?.username}
            </div>
        </div>
    )
}

export default UserAvatar;