import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class InvestmentServices {
    
    getInvestment({key}) {

        const configuration = {
            credentials: 'include',
            method: "get",
            url: API_URL + `/investment/${key}`,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
    getInvestments({page=0, ordering='-updated_at'}) {

        const limit = 25;
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/investments?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/investments?limit=${limit}&ordering=${ordering}` };

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
    getInvestmentInvestments({page=0, ordering='-updated_at', investment_id=false}) {

        const limit = 25;
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/investments?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/investments?limit=${limit}&ordering=${ordering}` };

        if(investment_id){
            const configuration = {
                credentials: 'include',
                method: "get",
                url: configAPIUrl + '&investments__id=' + investment_id,
            };
    
            // Send POST Query with Login Info
            return axios(configuration)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                error = new Error();
            });
        }        
    }
}

export default new InvestmentServices();