import React, {useState} from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import { useAuth } from '../../utils/useAuth';
import ReactGA from 'react-ga4';


const LoginForm = () => {
  const {login} = useAuth();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    // Login with Auth Service
    setLoading(true);
    login({username: values.username, password:values.password, setLoading: setLoading});
    ReactGA.event({
        category: 'auth',
        action: 'login',
    });
  };

  return (
    <Form
      name="normal_login"
      className="login-form m-0 py-2"
      layout="vertical"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <div className='mb-4'>
        <div className='p-0.5 text-white font-medium'>
            Username<span className='text-ecalmers-full'>*</span>
        </div>
        <Form.Item
            name="username"
            className='m-0'
            rules={[
                {
                    required: true,
                    message: 'Please input your Username!',
                }
            ]}>
            <Input 
              prefix={<UserOutlined className="site-form-item-icon" />} 
              className='text-slate-700'
              style={{borderRadius:'10px'}} 
              placeholder="Username" 
              size='large' />
        </Form.Item>
      </div>
      <div>
        <div className='p-0.5 text-white font-medium'>
            Password<span className='text-ecalmers-full'>*</span>
        </div>
        <Form.Item
          name="password"
          className='m-0'
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            className='text-slate-700'
            placeholder="Password"
            size='large'
            style={{borderRadius:'10px'}} 
          />
        </Form.Item>
      </div>
      <div className='p-1 m-0 my-2 flex w-full'>
        <div className='flex-1'>
          <Form.Item name="remember" valuePropName="checked" className='m-0' noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </div>
        <div>
          <a className="login-form-forgot" href="/password-recovery">
            Forgot password
          </a>          
        </div>
      </div>
      <Button type="primary" htmlType="submit" className="text-md p-2 md:p-1.5 font-semibold h-auto rounded-full w-full" loading={loading}>Log in</Button>
    </Form>
  );
};

export default LoginForm;