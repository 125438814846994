import { createContext, useContext, useMemo, useState } from "react";
import ReactGA from "react-ga4";

const EventTrackingContext = createContext();

export const EventTrackingProvider = ({ children }) => {

    const sendEvent = ({category, action, label='', value=false, nonInteraction=false, transport='xhr'}) => {
        ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: value,
            nonInteraction: nonInteraction,
            transport: transport,
        });
    }
    const customClickEvent = ({action,label=''}) => {
        let config = {
            category: 'custom',
            action:action
        }
        ReactGA.event(config);

    }
    const navClickEvent = ({type}) => {
        let config = {
            category: 'nav',
            action:`sidebar_click_${type.replace('/','')}`
        }
        ReactGA.event(config);
    }
    const vcClickEvent = ({type,name}) => {
        let config = {
            category: type,
            label: name,
        }

        if(type === 'investor'){
            config['action'] = `investor_click`
        }
        else if(type === 'fund'){
            config['action'] = `fund_click`
        }

        ReactGA.event(config);
    }

    const value = useMemo(
        () => ({
            sendEvent,
            navClickEvent,
            vcClickEvent,
            customClickEvent,
        }),[sendEvent,navClickEvent,vcClickEvent,customClickEvent]
    );
    return <EventTrackingContext.Provider value={value}>{children}</EventTrackingContext.Provider>;
};

export const useEventTracker = () => {
    return useContext(EventTrackingContext);
};