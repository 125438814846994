import { EnvironmentOutlined, ExportOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import LinkIconButton from "../../../components/Button/LinkIconButton";
import { useState } from "react";


function InvestorSimpleCard({values}){
    const [isHover,setIsHover] = useState(false);

    return(
        <div className="p-4 rounded-2xl bg-black text-white cursor-pointer h-full shadow-lg" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div className="flex items-start md:items-center gap-3 w-full mb-2">
                <div className="border rounded-lg">
                    {
                        values?.image_url
                        ? <div className="w-[60px] md:w-[80px] aspect-square rounded-lg border bg-cover bg-no-repeat bg-center" style={{backgroundImage:`url(${values?.image_url})`}}></div>
                        : <div className="w-[60px] md:w-[80px] aspect-square rounded-lg border bg-black flex items-center justify-center font-bold text-5xl" ><div>{values?.name.split(' ').map(value => value[0]).slice(0,2).join('')}</div></div>
                    }
                </div>
                <div className="flex flex-col gap-1.5">
                    <Link to={`/investor/${values.key}`}>
                        <div className="text-white flex items-center w-fit">
                            <h2 className="text-3xl md:text-2xl m-auto leading-none font-semibold w-fit">
                                {values?.name}
                            </h2>
                            <span className={`ml-2 text-lg transform duration-200 ease-in ${isHover ? 'opacity-100':'opacity-0'}`}>
                                <ExportOutlined/>
                            </span>
                        </div>
                    </Link>
                    {
                        (values?.role || values?.company) &&
                            <div className="text-sm leading-none">
                                <h3 className="m-auto leading-none mb-0">{values?.role && values?.role.split('\n\n')[0]} {values?.role && values?.company && 'at'} {values?.company && <Link to={`/fund/${values?.company?.key}`} className="underline">{values?.company?.name}</Link>}</h3>
                            </div>
                    }
                    {
                        values?.stages && values?.stages.length > 0 &&
                            <div className="flex flex-wrap gap-1 items-center ">
                                {
                                    values?.stages.slice(0,3).map((value,index) =>
                                        <div key={`stage-${index}`} className="text-base md:text-sm px-2 py-1 rounded-md bg-black">
                                            {value.name}
                                        </div>
                                    )
                                }
                                {
                                    values?.stages.length > 3 &&
                                        <div className="text-base md:text-sm">
                                            {`${values?.stages.length - 3} more stages`}
                                        </div>
                                }
                            </div>
                    }
                </div>
            </div>
            <div className="flex items-start gap-0 md:gap-3 w-full">
                <div className="w-0 md:w-[80px]"></div>
                <div className="p-1 w-full">
                    <div className="pt-2 border-t-2 flex items-center w-full" style={{borderColor:'rgba(255,255,255,0.1)'}}>
                        <div className="flex-1">
                            {
                                values?.location &&
                                    <div className="text-sm md:text-xs w-fit px-2 py-1 rounded-lg" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                                        <EnvironmentOutlined/> {values?.location?.name}
                                    </div>
                            }
                        </div>
                        <div className="flex gap-1">
                            {
                                values?.links && Object.keys(values?.links).length > 0 &&
                                    Object.keys(values?.links).map((value,index) =>
                                        <a href={values?.links?.[value]} target="_blank" key={`link-${index}`} className="hover:text-white hover:scale-105">
                                            <div className="flex items-center text-lg md:text-base cursor-pointer w-fit rounded-lg" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                                                <LinkIconButton type={value} url={values?.links?.[value]} size="base" />
                                            </div>
                                        </a>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestorSimpleCard;