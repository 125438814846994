import { GithubOutlined, LinkedinOutlined, MailOutlined, MenuFoldOutlined, MenuUnfoldOutlined, TwitchOutlined, TwitterOutlined } from "@ant-design/icons";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Helmet } from "react-helmet-async";
import { useMainContext } from "../../components/Context/MainContext";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import FilterServices from "../../utils/FilterServices";
import InvestorServices from "../investors/utils/InvestorServices";
import FundServices from "../funds/utils/FundServices";
import LocationServices from "../../utils/LocationServices";
import InvestorCard from "../investors/components/InvestorCard";
import FundCard from "../funds/components/FundCard";
import InvestorCardSkeleton from "../../components/Skeleton/InvestorCardSkeleton";
import myPic from '../../images/me/profilepic.webp';


function HomePage() {
    const {setSidebarCollapsed} = useMainContext();
    const [investors,setInvestors] = useState(null);
    const [investorCount,setInvestorCount] = useState(null);
    const [funds,setFunds] = useState(null);
    const [fundCount,setFundCount] = useState(null);
    const [stages,setStages] = useState(null);
    const [types,setTypes] = useState(null);
    const [industries,setIndustries] = useState(null);
    const [locations,setLocations] = useState(null);

    const getInvestors = async () => {
        const investorsResponse = await InvestorServices.getInvestors({page:0,limit:10});
        setInvestorCount(investorsResponse.count);
        setInvestors(investorsResponse.results);
    }
    const getFunds = async () => {
        const fundsResponse = await FundServices.getFunds({page:0,limit:10});
        setFundCount(fundsResponse.count);
        setFunds(fundsResponse.results);
    }
    const getStages = async () => {
        const stagesResponse = await FilterServices.getStages({page:0});
        setStages(stagesResponse.data);
    }
    const getTypes = async () => {
        const typesResponse = await FilterServices.getTypes({page:0});
        setTypes(typesResponse.results);
    }
    const getIndustries = async () => {
        const industriesResponse = await FilterServices.getIndustries({page:0});
        setIndustries(industriesResponse.results);
    }
    const getLocations = async () => {
        const locationsResponse = await LocationServices.getCountries({page:0});
        setLocations(locationsResponse.results);
    }

    useEffect(() => {
        getInvestors();
        getFunds();
        getStages();
        getTypes();
        getIndustries();
        getLocations();
    },[]);

    return(
        <div className="bg-black min-h-screen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`VC Hunt - Investments`}</title>
                <meta property="og:title" content={`VC Hunt - Investments`}/>
                <meta name="description" content={`VC Hunt - Explore Investments by Investors and VCs`} />
                <link rel="canonical" href="https://www.vc-hunt.com" />
            </Helmet>
            <div className="text-left">
                <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-4 w-full m-auto">
                    <div className="pl-8 py-8 static md:sticky top-0 h-fit md:h-screen">
                        <div className="flex flex-col text-white w-full md:w-11/12 m-auto h-full">
                            <div className="fixed top-0 left-0 right-0 bg-black h-20 p-4 shadow-lg flex items-center z-20 md:relative mb-4 mb-8 flex items-center">
                                <div className="flex-1">
                                    <Link to={'/'} className="hover:text-white">
                                        <h1>VC Hunt</h1>
                                    </Link>
                                </div>
                                <div className="block md:hidden text-2xl">
                                    <div className="rounded-xl bg-[#1B191C] px-3 aspect-square flex items-center justify-center" onClick={() => setSidebarCollapsed(true)}>
                                        <MenuFoldOutlined />
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block h-full">
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-3 lg:col-span-4 flex grid grid-cols-1 p-2 md:p-0 bg-black">
                        <div className="flex flex-col items-start text-white w-full md:w-10/12 lg:w-11/12 m-auto h-full">
                            <div className="flex items-center mb-8 md:mb-4 bg-black relative shadow-lg w-full h-auto">
                                <div className="pt-0 md:pt-4 pl-0">
                                    <h1 className="leading-none mb-0.5 text-5xl md:text-4xl">
                                        Data for<br/>
                                        Fundraising
                                    </h1>
                                </div>
                            </div>
                            <div className="w-full pt-8 pb-12 flex flex-col gap-2">
                                <div>
                                    <h2 className="text-4xl">
                                        Get Started
                                    </h2>
                                </div>
                                <div className="w-full md:w-11/12 grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
                                    <Link to='/investors'>
                                        <div className="w-full p-8 rounded-2xl bg-[#1B191C] text-white cursor-pointer text-3xl font-bold flex items-center">
                                            For Founders
                                        </div>
                                    </Link>
                                    <Link to='/companies'>
                                        <div className="w-full p-8 rounded-2xl bg-[#1B191C] text-white cursor-pointer text-3xl font-bold flex items-center">
                                            For Investors
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="w-full pb-12 flex flex-col gap-4">
                                <div>
                                    <h2 className="text-4xl">
                                        Recent Investors
                                        {investorCount && <span className="text-xl"> ({investorCount})</span>}
                                    </h2>
                                </div>
                                <div className="overflow-x-scroll hide-scrollbar">
                                    <div className="w-full flex flex-nowrap gap-4">
                                        {
                                            investors
                                            ?   investors.length > 0 &&
                                                investors.map((value,index) => 
                                                    <div className="w-[250px] aspect-[2/3] grow-0 shrink-0" key={`investor-${index}`}>
                                                        <InvestorCard values={value} />
                                                    </div>                                        
                                                )
                                            :   [1,2,3,4].map((_,index) =>
                                                    <div key={`investor-preview-${index}`} className="w-[250px] aspect-[2/3] grow-0 shrink-0" >
                                                        <InvestorCardSkeleton />
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                                <div>
                                    <Link to='/investors' className="underline">
                                        View All {investorCount && <span> {investorCount}</span>} Investors
                                    </Link>
                                </div>
                            </div>
                            <div className="w-full pb-12 flex flex-col gap-4">
                                <div>
                                    <h2 className="text-4xl">
                                        Recent Funds
                                        {fundCount && <span className="text-xl"> ({fundCount})</span>}
                                    </h2>
                                </div>
                                <div className="overflow-x-scroll hide-scrollbar">
                                    <div className="w-full flex flex-nowrap gap-4">
                                        {
                                            funds
                                            ?   funds.length > 0 &&
                                                funds.map((value,index) => 
                                                        <div key={`fund-${index}`} className="w-[250px] aspect-[2/3] grow-0 shrink-0">
                                                            <FundCard values={value} />
                                                        </div>                                        
                                                    )
                                            :   [1,2,3,4].map((_,index) =>
                                                    <div key={`fund-preview-${index}`} className="w-[250px] aspect-[2/3] grow-0 shrink-0" >
                                                        <InvestorCardSkeleton />
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                                <div>
                                    <Link to='/funds' className="underline">
                                        View All {fundCount && <span> {fundCount}</span>} Funds
                                    </Link>
                                </div>
                            </div>
                            <div className="w-full pb-12">
                                <div>
                                    <h2>VC Hunt Lists</h2>
                                </div>
                                <div>

                                    {
                                        stages && stages.length > 0 &&
                                            stages.map((value) => 
                                                <div>
                                                    <Link to={`/investors?stage=${value?.key}`}>
                                                        Top {value?.name} Investors
                                                    </Link>
                                                </div>
                                            )
                                    }
                                    {
                                        types && types.length > 0 &&
                                        types.map((value) => 
                                            <div>
                                                <Link to={`/investors?type=${value?.key}`}>
                                                    Top {value?.name} Investors
                                                </Link>
                                            </div>
                                            )
                                    }
                                    {
                                        industries && industries.length > 0 &&
                                        industries.map((value) => 
                                            <div>
                                                <Link to={`/investors?industry=${value?.key}`}>
                                                    Top {value?.name} Investors
                                                </Link>
                                            </div>
                                            )
                                    }
                                    {
                                        locations && locations.length > 0 &&
                                            locations.map((value) => 
                                                <div>
                                                    <Link to={`/investors?location=${value?.key}`}>
                                                        Top Investors in {value?.name}
                                                    </Link>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                            <div className="w-full pb-20">
                                <div className="p-10 rounded-2xl bg-[#1B191C] text-white w-full flex flex-col gap-4">
                                    <div className="block md:flex gap-10">
                                        <div className="mb-4">
                                            <div className="w-[100px]">
                                                <img src={myPic} className="rounded-full" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="mb-4">
                                                <h2 className="text-4xl m-0 leading-tight">✌️ About Me</h2>
                                            </div>
                                            <div className="text-xl">
                                                <div className="mb-6">
                                                    Hey! <br/>
                                                    My name is <strong>Daiki Minaki</strong>.<br/>
                                                    Thanks for checking out my latest project - VCHunt.<br/>
                                                </div>
                                                <div className="mb-6">
                                                    <h3 className="font-semibold leading-none md:leading-snug mb-4">
                                                        If You want any Custom Features/Exports or Find Any Bugs:
                                                    </h3>
                                                    <a href='mailto:me@daikiminaki.com' target="_blank">
                                                        <div className="border w-full md:w-1/2 p-2 text-center text-base rounded-md hover:font-semibold cursor-pointer">
                                                            <MailOutlined/> Email Me
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="mb-4">
                                                    <h3 className="font-semibold mb-2">
                                                        Connect with me:
                                                    </h3>
                                                    <div className="flex gap-3 text-4xl">
                                                        <a href='https://www.linkedin.com/in/daiki-minaki/' target="_blank"><LinkedinOutlined/></a>
                                                        <a href='https://github.com/daikiminaki' target="_blank"><GithubOutlined/></a>
                                                        <a href='https://x.com/DaikiMinaki' target="_blank"><TwitterOutlined/></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage;