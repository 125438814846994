

function LinkButton({value,icon}){
    return(
        <div className="bg-black w-full p-2 text-white bg-[#5662e2] text-center text-lg md:text-base rounded-md cursor-pointer">
            {icon} {value}
        </div>
    )
}

export default LinkButton;