import { LoadingOutlined } from '@ant-design/icons';

function LoadingPage() {

    return (
        <div className='absolute top-0 bottom-0 right-0 left-0 bg-white'>
            <div className='flex align-center justify-center'>
                <LoadingOutlined style={{fontSize:'40px',color:'rgba(84,51,255, 1)'}} />
            </div>
        </div>
    )
}

export default LoadingPage;