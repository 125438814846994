import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "../../utils/useLocalStorage";
import MobileMenuDrawer from "../Drawer/MobileMenuDrawer";
import { ConfigProvider } from "antd";
import LoginModal from "../Modal/LoginModal";

const MainContext = createContext();

export const MainProvider = ({ children }) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useLocalStorage("sidebarCollapsed",false);
    const [isLoginModalVisible, setIsLoginModalVisible] = useLocalStorage("isLoginModalVisible",false);

    const closeModal = () => {
        setIsLoginModalVisible(false);
    }

    const value = useMemo(
        () => ({
            sidebarCollapsed,setSidebarCollapsed,
            isLoginModalVisible,setIsLoginModalVisible,
        }),[sidebarCollapsed,isLoginModalVisible]
    );
    
    return(
        <ConfigProvider
            theme={{
                components: {
                    Pagination: {
                        itemBg:'#1B191C',
                        itemActiveBg:'rgba(255,255,255,0.6)',
                    },
                    Modal: {
                        contentBg:'#1B191C',
                    }
                },
                token: {
                    colorText:'white',
                    colorPrimary:'black'
                },
              
            }}
            >
            <MainContext.Provider value={value}>
                {children}
                <MobileMenuDrawer sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
                <LoginModal isLoginModalVisible={isLoginModalVisible} closeModal={closeModal} />
            </MainContext.Provider>
        </ConfigProvider>
    );
};

export const useMainContext = () => {
    return useContext(MainContext);
};