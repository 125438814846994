import { MailOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import Sidebar from "../Sidebar/Sidebar";


function MobileMenuDrawer({sidebarCollapsed, setSidebarCollapsed}) {

    return(
        <Drawer 
            width={'100%'}
            open={sidebarCollapsed}
            closable={false}
            placement="right"
            styles={{'body':{padding:0,backgroundColor:'black',color:'white'}}}
            >
            <div className="flex flex-col w-full h-full p-4">
                <div className="flex flex-col flex-1">
                    <div className="bg-black h-20 p-4 shadow-lg flex items-center mb-4 mb-8">
                        <div className="flex-1">
                            <h1>VC Hunt</h1>
                        </div>
                        <div className="block md:hidden text-2xl">
                            <div className="rounded-xl bg-[#1B191C] px-3 aspect-square flex items-center justify-center" onClick={() => setSidebarCollapsed(false)}>
                                <MenuUnfoldOutlined/>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <Sidebar />
                    </div>
                </div>
                <div className="bg-[#1B191C] rounded-xl p-6 flex flex-col gap-3">
                    <div>
                        <h3 className="m-auto mb-0 leading-none font-medium text-xl">Need Anything?</h3>
                    </div>
                    <div>
                        If you have any questions, comments or feature request just send me an email!
                    </div>
                    <a href="mailto:me@daikiminaki.com" target='_blank'>
                        <div className="p-3 bg-black rounded-md text-base text-center w-full">
                            <MailOutlined/> Send us an email!
                        </div>
                    </a>
                </div>
            </div>
        </Drawer>
    )
}

export default MobileMenuDrawer;