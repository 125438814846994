import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class FilterServices {
    getStages({page=0, limit=15, ordering='-updated_at'}) {

        const offset = limit * page || 0;

        var configAPIUrl;

        if(page === false){
            configAPIUrl = API_URL + `/stages?ordering=${ordering}`
        }
        else {
            if (offset > 0){configAPIUrl = API_URL + `/stages?limit=${limit}&offset=${offset}&ordering=${ordering}`}
            else { configAPIUrl = API_URL + `/stages?limit=${limit}&ordering=${ordering}` };
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    getTypes({page=0, limit=15, ordering='-updated_at'}) {

        const offset = limit * page || 0;

        var configAPIUrl = API_URL

        if(page === false){
            configAPIUrl = API_URL + `/types?ordering=${ordering}`
        }
        else {
            if (offset > 0){configAPIUrl = API_URL + `/types?limit=${limit}&offset=${offset}&ordering=${ordering}`}
            else { configAPIUrl = API_URL + `/types?limit=${limit}&ordering=${ordering}` };
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    getIndustries({page=0, limit=15, ordering='-updated_at'}) {

        const offset = limit * page || 0;

        var configAPIUrl = API_URL

        if(page === false){
            configAPIUrl = API_URL + `/industries?ordering=${ordering}`
        }
        else {
            if (offset > 0){configAPIUrl = API_URL + `/industries?limit=${limit}&offset=${offset}&ordering=${ordering}`}
            else { configAPIUrl = API_URL + `/industries?limit=${limit}&ordering=${ordering}` };
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
}

export default new FilterServices();