import { CoffeeOutlined, GlobalOutlined, LinkedinOutlined, LinkOutlined, MailOutlined, WhatsAppOutlined, XOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const iconList = {
    'website':<GlobalOutlined/>,
    'linkedin':<LinkedinOutlined/>,
    'whatsapp':<WhatsAppOutlined/>,
    'twitter':<XOutlined />,
    'email':<MailOutlined/>,
    'crunchbase':<div>cb</div>,
    'angel':<CoffeeOutlined/>,
    'link':<LinkOutlined/>
}


function LinkIconButton({type,url,size='base'}){
    return(
        <Tooltip title={type} placement="bottom" >
            <div className={`aspect-square flex items-center justify-center cursor-pointer text-white ${{'sm':'w-6 text-sm','base':'w-8 text-base','lg':'w-10 text-2xl'}[size]}`} >
                {iconList?.[type] ? iconList?.[type] : iconList['link']}
            </div>
        </Tooltip>
    )
}

export default LinkIconButton;