import { EnvironmentOutlined, ExportOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import LinkIconButton from "../../../components/Button/LinkIconButton";
import { useState } from "react";


function InvestorCard({values,color='#1B191C'}){
    const [isHover,setIsHover] = useState(false);

    return(
        <div className={`p-6 px-4 rounded-2xl bg-[${color}] text-white cursor-pointer w-full h-full w-full shadow-lg flex flex-col items-start`} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div className="flex-1 w-full overflow-y-scroll hide-scrollbar">
                <div className="flex flex-col gap-3 justify-center text-center">
                    <div>
                        {
                            values?.image_url
                            ? <div className="w-[80px] aspect-square rounded-lg m-auto border bg-cover bg-no-repeat bg-center" style={{backgroundImage:`url(${values?.image_url})`}}></div>
                            : <div className="w-[80px] aspect-square rounded-lg m-auto border bg-black flex items-center justify-center font-bold text-5xl" ><div>{values?.name.split(' ').map(value => value[0]).slice(0,2).join('')}</div></div>
                        }
                    </div>
                    <div className="flex flex-col gap-2">
                        <h2 className="m-auto leading-none font-semibold w-fit text-2xl">
                            {values?.name}
                        </h2>
                        {
                            (values?.role || values?.company) &&
                                <div className="text-sm leading-none">
                                    <h4 className="m-auto leading-tight mb-0">{values?.role && values?.role.split('\n\n')[0]} {values?.role && values?.company && 'at'} {values?.company && <Link to={`/fund/${values?.company?.key}`} className="underline">{values?.company?.name}</Link>}</h4>
                                </div>
                        }
                    </div>
                    <div className="flex justify-center">
                        {
                            values?.links && Object.keys(values?.links).length > 0 &&
                                Object.keys(values?.links).map((value,index) =>
                                    <a href={values?.links?.[value]} target="_blank" className="hover:text-white hover:scale-105">
                                        <div key={`link-${index}`} className="flex items-center text-base cursor-pointer w-fit rounded-lg" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                                            <LinkIconButton type={value} url={values?.links?.[value]} size="base" />
                                        </div>
                                    </a>
                                )
                        }
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="w-full">
                            {
                                values?.stages && values?.stages.length > 0 &&
                                    <div className="flex flex-wrap gap-0.5 leading-none justify-center m-auto">
                                        {
                                            values?.stages.slice(0,3).map((value,index) =>
                                                <div key={`stage-${index}`} className="text-xs px-2 py-1 rounded-md bg-black">
                                                    {value.name}
                                                </div>
                                            )
                                        }
                                        {
                                            values?.stages.length > 3 &&
                                                <div className="text-xs">
                                                    {`+ ${values?.stages.length - 3}`}
                                                </div>
                                        }
                                    </div>
                            }
                        </div>
                        <div className="text-center">
                            {
                                values?.location &&
                                    <div className="text-xs w-fit px-2 py-1 rounded-lg m-auto" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                                        <EnvironmentOutlined/> {values?.location?.name}
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center w-full">
                <Link to={`/investor/${values?.key}`} className="w-full">
                    <div className="p-2 text-base text-center w-full rounded-md" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                        <PlusOutlined/> Connect
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default InvestorCard;