import { Skeleton } from "antd";

function InvestorPreviewSkeleton() {
    return(
        <div className="rounded-2xl bg-[#1B191C] w-full aspect-[4/1] p-6">
            <div className="flex items-start gap-4 w-full mb-4">
                <div className="w-[80px] aspect-square rounded-lg overflow-hidden border">
                    <Skeleton.Input style={{height:'80px'}} active={true} block />
                </div>
                <div className="flex-1">
                    <div className="w-1/2 rounded-md overflow-hidden mb-4">
                        <Skeleton.Input style={{height:'32px'}} active={true} block />
                    </div>
                    <div className="flex-1 flex items-center flex-col gap-1 justify-start">
                        <div className="flex flex-col gap-1 w-full">
                            <Skeleton.Input style={{height:'16px'}} active={true} block />
                            <Skeleton.Input style={{height:'16px'}} active={true} block />
                            
                        </div>
                        <div className="w-full">
                            <div className="w-9/12">
                                <Skeleton.Input style={{height:'16px'}} active={true} block />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestorPreviewSkeleton;