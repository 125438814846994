import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class LocationServices {
    getLocations({page=0, ordering='-updated_at'}) {

        const limit = 25;
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/locations?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/locations?limit=${limit}&ordering=${ordering}` };

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
    getCountries({page=0, limit=15, ordering='-updated_at'}) {

        const offset = limit * page || 0;

        var configAPIUrl = API_URL

        if(page === false){
            configAPIUrl = configAPIUrl + `/countries?ordering=${ordering}`
        }
        else{
            if (offset > 0){configAPIUrl = API_URL + `/countries?limit=${limit}&offset=${offset}&ordering=${ordering}`}
            else { configAPIUrl = API_URL + `/countries?limit=${limit}&ordring=${ordering}` };
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
}

export default new LocationServices();