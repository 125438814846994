import { BankOutlined, DollarOutlined, OrderedListOutlined, ShopOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../Context/MainContext";
import { useEventTracker } from "../Context/EventTrackingContext";
import { useAuth } from "../../utils/useAuth";
import UserAvatar from "../Avatar/UserAvatar";

function Sidebar(){
	const {user} = useAuth();
	const {customClickEvent} = useEventTracker();
	const {setSidebarCollapsed,setIsLoginModalVisible} = useMainContext();
	const navigate = useNavigate();
	
    const sidebarItems = [
        {
			key: '1',
			icon: <UsergroupAddOutlined />,
			label: 'Find Investors',
			url:'/investors'
        },
        {
			key: '2',
			icon: <BankOutlined />,
			label: 'Find Funds',
			url:'/funds'
        },
        {
			key: '3',
			icon: <DollarOutlined />,
			label: 'Find Investments',
			url:'/investments'
        },
        {
			key: '4',
			icon: <ShopOutlined />,
			label: 'Find Companies',
			url:'/companies'
        },
        {
			key: '5',
			icon: <OrderedListOutlined />,
			label: 'For You',
			url:'/recommendations'
        },
    ]

	const clickMenu = (url) => {
		setSidebarCollapsed(false);
		navigate(url);
		window.scrollTo({top: 0, behavior: 'smooth'});
	}
	const clickLogin = () => {
		setIsLoginModalVisible(true);
		customClickEvent({action:'create_sidebar_login'});
	}

    return(
		<div className="h-full flex flex-col">
			<div className="flex-1 flex flex-col gap-5 md:gap-3">
				{
					sidebarItems.map((value,index) => 
						<div key={`sidebar-item-${index}`} onClick={() => clickMenu(value['url'])}  className="flex items-center gap-4 md:gap-3 hover:bg-[#1B191C] rounded-xl cursor-pointer">
							<div className="flex items-center justify-center aspect-square rounded-lg bg-[#1B191C]">
								<div className="text-2xl md:text-lg px-4">
									{value?.icon}
								</div>
							</div>
							<div className="text-white text-2xl md:text-lg leading-none">
								{value.label}
							</div>
						</div>
					)
				}
			</div>
			{
				user !== undefined
				?
					<div>
						<UserAvatar user={user} />
					</div>
				:	
					<div onClick={() => clickLogin()} className="bg-[#1B191C] rounded-lg p-2 w-full text-center cursor-pointer hover:font-semibold">
						Login / Register
					</div>
			}
		</div>
    )
}

export default Sidebar;