import { LoadingOutlined, MenuFoldOutlined, PlusOutlined, UnlockOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import { Helmet } from "react-helmet-async";
import { Link, useSearchParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { useState } from "react";
import { useMainContext } from "../../../components/Context/MainContext";


function CompaniesPage() {
    const [searchParams,setSearchParams] = useSearchParams();
    const {setSidebarCollapsed} = useMainContext();
    const [companyList,setCompanyList] = useState(null);
    const [companyCount,setCompanyCount] = useState(null);
    const [loading,setLoading] = useState(false);
    const [page,setPage] = useState(0);
    const [isFilterDrawerVisible,setIsFilterDrawerVisible] = useState(false);

    const onChangePage = () => {

    }

    return(
        <div className="bg-black min-h-screen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`VC Hunt - Find a Company`}</title>
                <meta property="og:title" content={`VC Hunt - Find a Company`}/>
                <meta name="description" content={`VC Hunt - Find a Company`} />
                <link rel="canonical" href="https://www.vc-hunt.com" />
            </Helmet>
            <div className="text-left">
                <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-4 w-full m-auto">
                    <div className="pl-8 py-8 static md:sticky top-0 h-screen">
                        <div className="flex flex-col text-white w-full md:w-11/12 h-full m-auto">
                            <div className="fixed top-0 left-0 right-0 bg-black h-20 p-4 shadow-lg flex items-center z-20 md:relative mb-4 mb-8 flex items-center">
                                <div className="flex-1">
                                    <Link to='/'>
                                        <h1>VC Hunt</h1>
                                    </Link>
                                </div>
                                <div className="block md:hidden text-2xl">
                                    <div className="rounded-xl bg-[#1B191C] px-3 aspect-square flex items-center justify-center" onClick={() => setSidebarCollapsed(true)}>
                                        <MenuFoldOutlined/>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block h-full">
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-3 lg:col-span-4 flex grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 p-2 md:p-0 bg-black">
                        <div className="col-span-1 md:col-span-3 flex flex-col items-start h-full text-white w-full md:w-10/12 lg:w-11/12 m-auto pb-0 lg:pb-12">
                            <div className="h-20 md:h-28 flex items-center mb-8 md:mb-0 bg-black relative md:sticky top-0 z-10 shadow-lg w-full h-auto">
                                <div className="pt-0 md:pt-4 pl-2 md:pl-0">
                                    <h1 className="leading-none mb-0.5 text-5xl md:text-4xl">
                                        Explore Companies
                                    </h1>
                                    {
                                        companyCount &&
                                            <div>
                                                <h4 className="m-auto font-medium">{companyCount} Results</h4>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 w-full md:w-11/12 mb-4">
                                {
                                    loading &&
                                        <div className="text-center text-xl">
                                            <LoadingOutlined />
                                        </div>
                                }
                                <div className="p-10 rounded-2xl bg-[#1B191C] text-white w-full flex flex-col gap-4">
                                    <div>
                                        <h2 className="m-0 leading-tight">Are you an Investor?</h2>
                                    </div>
                                    <div className="mb-4">
                                        <h3 className="m-0 leading-tight text-xl">
                                            Get Early Access to Our Company and Founder Data!
                                        </h3>
                                    </div>
                                    <div>
                                        <div className="border w-full md:w-1/2 p-2 text-center text-base rounded-md hover:font-semibold cursor-pointer">
                                            <UnlockOutlined/> Request Access
                                        </div>
                                    </div>
                                </div>
                                <div className="p-10 rounded-2xl bg-[#1B191C] text-white w-full flex flex-col gap-4">
                                    <div>
                                        <h2 className="m-auto leading-tight">Are you a Founder?</h2>
                                    </div>
                                    <div className="mb-4">
                                        <h3 className="m-auto leading-tight text-xl">
                                            Make it Easier for Investors to Find You.
                                        </h3>
                                    </div>
                                    <div>
                                        <div className="border w-full md:w-1/2 p-2 text-center text-base rounded-md hover:font-semibold cursor-pointer">
                                            <PlusOutlined/> Add Your Startup
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                companyCount &&
                                    <div className="w-full flex justify-center py-2">
                                        <Pagination pageSize={20} current={page + 1} total={companyCount} onChange={(value) => onChangePage(value)} showSizeChanger={false} />
                                    </div>
                            }
                        </div>
                        <div className="col-span-1 md:col-span-3 lg:col-span-2 w-full pt-4 pb-12">
                            {
                                false &&
                                    <div className="relative md:sticky top-0 pt-0 lg:pt-28  w-full md:w-10/12 m-auto lg:m-0">
                                        <div className="p-8 rounded-2xl bg-[#1B191C] text-white w-full md:w-11/12">
                                            <h4 className="text-lg m-auto font-semibold">
                                                Your Saved Investors
                                            </h4>
                                            <div>

                                                
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompaniesPage;