import React, { useEffect, useState } from 'react';
import {Button, Form, Input, message} from 'antd';
import AuthServices from '../../utils/AuthServices';
import { CheckCircleFilled, CloseCircleOutlined, LoadingOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../utils/useAuth';
import ReactGA from 'react-ga4';

const RegistrationForm = ({username=false, onSuccess=false, autoFocus=true}) => {
  const [loading, setLoading] = useState(false);
  const {register} = useAuth();
  const [form] = Form.useForm();
  const [usernameValue,setUsernameValue] = useState('');
  const [emailValue,setEmailValue] = useState('');
  const [userValidating, setUserValidating] = useState(false);
  const [emailValidating, setEmailValidating] = useState(false);
  const [userValidated, setUserValidated] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  const [disableRegister,setDisableRegister] = useState(false);

  const usernameChange = (value) => {
    setUsernameValue(value.target.value);
  }
  const emailChange = (value) => {
    setEmailValue(value.target.value);
  }

  const focusInput = () => {
    if(username){
      const element = document.getElementById('email-input');
      element.focus();
    }
    else{
      const element = document.getElementById('username-input');
      element.focus();
    }
  }
  const showError = (values) => {
    values.forEach((value) => {
      message.error(value);
    });
  }
  const onFinish = async (values) => {
    setLoading(true);
    form.validateFields();
    const registerResponse = await register({username: values.username, email: values.email, password: values.password, showError:showError, onSuccess:onSuccess});
    ReactGA.event({
      category: 'auth',
      action: 'register',
    });
    if(registerResponse.status !== 201){
      setLoading(false);
    }
  };

  useEffect(() => {
    if(username){
        form.setFieldsValue({username:username});
        setUsernameValue(username);
        setUserValidated(true);
    }
    focusInput();
},[username]);

  return (
    <Form
      form={form}
      name="register"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
      >
      <div className='mb-1'>
        <div className='p-0.5 text-white font-medium'>
          Username<span className='text-ecalmers-full'>*</span>
        </div>
        <div id='username-input-div' className='relative'>
          <Form.Item
            name="username"
            className='m-0'
            rules={[
              {
                required: true,
                message: 'Please input your name!',
                whitespace: true,
              },
              {
                  validator: async (_, value) => {
                      setUserValidating(true);
                      const check = await AuthServices.checkRegister({'username': value});
                      setUserValidating(false);
                      if(value.includes('_')){
                          setUserValidated(false);
                          return Promise.reject('Username Cannot Contain Underscore.');
                      }
                      else if(value.includes(' ')){
                          setUserValidated(false);
                          return Promise.reject('Username Cannot Contain Space.');
                      }
                      else if(check.usernameUnique){
                          setUserValidated(true);
                          return Promise.resolve();
                      } 
                      else {
                          setUserValidated(false);
                          return Promise.reject('This Username Already Exists!');
                      }
                  }
              }
            ]} >
            <Input 
              id='username-input'
              className='rounded-xl text-slate-700'
              size='large' 
              prefix={<UserOutlined className="site-form-item-icon" />} 
              suffix={usernameValue !== '' ? userValidating ? <LoadingOutlined style={{fontSize:'14px'}} /> : userValidated ? <CheckCircleFilled style={{color:'#52c41a',fontSize:'14px'}} /> : <CloseCircleOutlined style={{color:'#f5222d',fontSize:'14px'}} /> : <CloseCircleOutlined style={{opacity:0}} />} 
              placeholder="Enter a Username" 
              value={usernameValue} 
              onChange={usernameChange} 
              autoFocus={autoFocus} />
          </Form.Item>
        </div>
      </div>
      <div className='mb-1'>
        <div className='p-0.5 font-medium text-white'>
          Email<span className='text-ecalmers-full'>*</span>
        </div>
        <Form.Item
          name="email"
          className='m-0'
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
            {
                validator: async (_, value) => {
                    setEmailValidating(true);
                    const check = await AuthServices.checkRegister({'email': value});
                    setEmailValidating(false);
                    if (check.emailUnique) {
                        setEmailValidated(true);
                        return Promise.resolve();
                    } else {
                        setEmailValidated(false);
                        return Promise.reject('This Email has already been used!');
                    }
                }
            }
          ]}
          >
          <Input id='email-input'
            size='large' 
            className='rounded-xl text-slate-700'
            prefix={<MailOutlined className="site-form-item-icon" />} 
            suffix={emailValue !== '' ? emailValidating ? <LoadingOutlined style={{fontSize:'14px'}} /> : emailValidated ? <CheckCircleFilled style={{color:'#52c41a',fontSize:'14px'}} /> : <CloseCircleOutlined style={{color:'#f5222d',fontSize:'14px'}} /> : <CloseCircleOutlined style={{opacity:0}} />} 
            placeholder="Enter Your Email" 
            value={emailValue} 
            onChange={emailChange} />
        </Form.Item>
      </div>
      <div className='mb-4'>
        <div className='p-0.5 text-white font-medium'>
          Password
        </div>
        <Form.Item
          name="password"
          className='m-0'
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} size='large' placeholder="Enter a Password" className='rounded-xl text-slate-700' />
        </Form.Item>
      </div>
      <Form.Item>
        <Button type="primary" id='sign-up-button' className="rounded-full h-auto text-md p-2 md:p-1.5 font-semibold w-full hover-scale-sm" htmlType="submit" loading={loading} disabled={disableRegister} >
          Sign Up
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegistrationForm;