import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class FundServices {
    
    getFund({key}) {

        const configuration = {
            credentials: 'include',
            method: "get",
            url: API_URL + `/fund/${key}`,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
    getFunds({page=0, limit=20, ordering='-updated_at', stage=false, industry=false, country=false, location=false, type=false, region=false, search=false}) {
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/funds?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/funds?limit=${limit}&ordering=${ordering}` };

        if(stage && stage !== 'all'){
            configAPIUrl = configAPIUrl + `&stages__key=${stage}`;
        }
        if(industry && industry !== 'all'){
            configAPIUrl = configAPIUrl + `&industries__key=${industry}`;
        }
        if(location && location !== 'all') {
            configAPIUrl = configAPIUrl + `&location__key=${location}`;
        }
        if(country){
            configAPIUrl = configAPIUrl + `&hq_location__country__key=${country}`;
        }
        if(type){
            configAPIUrl = configAPIUrl + `&types__key=${type}`;
        }
        if(region){
            configAPIUrl = configAPIUrl + `&regions__key=${region}`;
        }
        if(search) {
            configAPIUrl = configAPIUrl + `&search=${search}`;
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }

    getFundInvestments({page=0, ordering='-updated_at', fund_id=false}) {

        const limit = 25;
        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/investments?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/investments?limit=${limit}&ordering=${ordering}` };

        if(fund_id){
            const configuration = {
                credentials: 'include',
                method: "get",
                url: configAPIUrl + '&funds__id=' + fund_id,
            };
    
            // Send POST Query with Login Info
            return axios(configuration)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                error = new Error();
            });
        }        
    }
}

export default new FundServices();