import { PlusOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";

function InvestorCardSkeleton() {
    return(
        <div className="rounded-2xl bg-[#1B191C] w-full h-full aspect-[4/1] p-6 flex flex-col">
            <div className="flex flex-col justify-center gap-4 w-full mb-4 flex-1">
                <div className="w-[80px] aspect-square rounded-lg overflow-hidden border m-auto">
                    <Skeleton.Input style={{height:'80px'}} active={true} block />
                </div>
                <div className="w-1/2 rounded-md overflow-hidden mb-4 m-auto">
                    <Skeleton.Input style={{height:'24px'}} active={true} block />
                </div>
                <div className="flex-1 flex items-center flex-col gap-1 justify-start">
                    <div className="flex flex-col gap-1 w-full">
                        <Skeleton.Input style={{height:'16px'}} active={true} block />
                        <Skeleton.Input style={{height:'16px'}} active={true} block />
                        
                    </div>
                    <div className="w-full">
                        <div className="w-1/2 m-auto overflow-hidden">
                            <Skeleton.Input style={{height:'16px'}} active={true} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="p-2 text-base text-center w-full rounded-md blur-[2px]" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                    <PlusOutlined/> Connect
                </div>
            </div>
        </div>
    )
}

export default InvestorCardSkeleton;