import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FundServices from "../utils/FundServices";
import BasicRadarChart from "../../../components/Chart/RadarChart";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { Helmet } from "react-helmet-async";
import { EnvironmentOutlined, HeartOutlined, LeftOutlined, MenuFoldOutlined, PlusOutlined } from "@ant-design/icons";
import InvestmentTable from "../../../components/Table/InvestmentTable";
import LinkIconButton from "../../../components/Button/LinkIconButton";
import { useMainContext } from "../../../components/Context/MainContext";
import LinkButton from "../../../components/Button/LinkButton";
import InvestorSimpleCard from "../../investors/components/InvestorSimpleCard";
import { Skeleton } from "antd";


function FundPage(){
    const params= useParams();
    const {setSidebarCollapsed} = useMainContext();
    const [fund,setFund] = useState(null);
    const [investments,setInvestments] = useState(null);

    const getFund = async () => {
        if(params.key){
            const fundResponse = await FundServices.getFund({key:params.key});
            setFund(fundResponse.data);
            getInvestments(fundResponse.data.id);
        }
    }
    const getInvestments = async (fund_id) => {
        const investmentResponse = await FundServices.getFundInvestments({page:0,fund_id:fund_id});
        setInvestments(investmentResponse.results);
    }

    useEffect(() => {
        window.scrollTo({top:0});
        getFund();
    },[params.key]);
    return(
        <div className="bg-black min-h-screen h-full pb-8 text-left">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{fund?.name ? `${fund?.name} | Fund Profile | VC Hunt` : 'Fund Profile | VC Hunt'}</title>
                <meta property="og:title" content={fund?.name ? `${fund?.name} | Fund Profile | VC Hunt` : 'Fund Profile | VC Hunt'}/>
                <meta name="description" content={fund?.name ? `${fund?.name}, ${fund?.role}'s Fund. ${fund?.description}. Profile for Venture Capital Investors and Funds to help Companies Network and save time when raising capital.` : 'Fund Profile - VC Hunt'} />
                <link rel="canonical" href="https://www.vc-hunt.com" />
            </Helmet>
            <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-4 w-full h-full m-auto relative">
                <div className="pl-8 py-8 static md:sticky top-0 h-screen">
                    <div className="flex flex-col text-white w-full md:w-11/12 h-full m-auto">
                        <div className="fixed top-0 left-0 right-0 bg-black h-20 p-4 shadow-lg flex items-center z-20 md:relative mb-4 mb-8 flex items-center">
                            <div className="flex-1">
                                <Link to='/'>
                                    <h1>VC Hunt</h1>
                                </Link>
                            </div>
                            <div className="block md:hidden text-2xl">
                                <div className="rounded-xl bg-[#1B191C] px-3 aspect-square flex items-center justify-center" onClick={() => setSidebarCollapsed(true)}>
                                    <MenuFoldOutlined />
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block h-full">
                            <Sidebar />
                        </div>
                    </div>
                </div>
                <div className="col-span-1 md:col-span-3 lg:col-span-4 flex grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 text-left">
                    <div className="col-span-3 p-4 md:p-8 h-full flex justify-center lg:justify-end">
                        <div className="w-full md:w-11/12 flex flex-col gap-4 pb-0 lg:pb-8">
                            <div>
                                <Link to='/funds'>
                                    <LeftOutlined/> Back
                                </Link>
                            </div>
                            <div className="p-8 rounded-3xl bg-[#1B191C] text-white h-full shadow-lg relative overflow-hidden">
                                <div className="flex flex-col gap-4">
                                    <div className="absolute top-0 left-0 right-0 aspect-[3/1] md:aspect-[4/1] overflow-hidden">
                                        {
                                            fund?.image_url
                                            ? <div className="w-full aspect-[4/1] bg-cover bg-no-repeat bg-center brightness-50 blur-lg" style={{backgroundImage:`url(${fund?.image_url})`}}></div>
                                            : <div className="w-full aspect-[4/1] bg-black brightness-50 blur-lg"></div>
                                        }
                                    </div>
                                    <div className="pt-4 z-10 mb-2">
                                        {
                                            fund
                                            ?
                                                fund?.image_url
                                                ? <div className="w-1/2 md:w-1/4 aspect-square rounded-xl border bg-cover bg-no-repeat bg-center" style={{backgroundImage:`url(${fund?.image_url})`}}></div>
                                                : <div className="w-1/2 md:w-1/4 aspect-square rounded-xl border bg-black flex items-center justify-center font-bold text-5xl" ><div>{fund?.name.split(' ').map(value => value[0]).slice(0,2).join('')}</div></div>
                                            :   <div className="w-1/2 md:w-1/4 aspect-square rounded-xl border overflow-hidden bg-[#1B191C]">
                                                    <Skeleton.Input style={{height:'400px'}} active={true} block />
                                                </div>
                                        }                                        
                                    </div>
                                    <div className="flex flex-col text-left gap-2">
                                        <div className="mb-1 md:mb-2">
                                            <div className="mb-3">
                                                {
                                                    fund
                                                    ?   <h1 className="text-3xl m-0 leading-none font-bold">{fund?.name}</h1>
                                                    :   <Skeleton.Input style={{height:'30px'}} active={true} block/>
                                                }                                                
                                            </div>
                                        </div>
                                        {
                                            fund?.descriptions && fund?.descriptions.length > 0 &&
                                                <div className="w-full leading-snug text-base bg-[#111111] p-2 md:p-4 rounded-md mb-1">
                                                    <div>
                                                        {fund?.descriptions[0]}
                                                    </div>
                                                </div>
                                        }
                                        <div className="p-8 bg-black mb-4 rounded-sm">
                                            <div>
                                                <h3 className="m-0 leading-none text-xl font-bold">Connect with {fund?.name}</h3>
                                            </div>
                                            <div className="pt-4">
                                                <div className="flex grid grid-cols-2 w-full gap-1">
                                                    {
                                                        fund?.links && Object.keys(fund?.links).length > 0 &&
                                                            Object.keys(fund?.links).map((value,index) =>
                                                                <a href={fund?.links?.[value]} target="_blank" key={`link-${index}`} className="hover:text-white hover:scale-105">
                                                                    <div className="flex items-center text-base cursor-pointer w-fit">
                                                                        <LinkIconButton type={value} url={fund?.links?.[value]} size="base" /> {value}
                                                                    </div>
                                                                </a>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2 pb-4">
                                            {
                                                fund?.location &&
                                                    <div className="flex items-center gap-1">
                                                        <div className="flex-1 pl-0.5">
                                                            <h3 className="m-0 font-semibold">Location:</h3>
                                                        </div>
                                                        <div className="flex gap-1 text-base px-2 py-1 rounded-md bg-black cursor-pointer w-fit">
                                                            <EnvironmentOutlined/>
                                                            <div>
                                                                {fund?.location?.city && `${fund?.location?.city?.name}, `}{fund?.location?.country?.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                fund?.check_size &&
                                                    <div className="flex items-center gap-1">
                                                        <div className="flex-1 pl-0.5">
                                                            <h3 className="m-0 font-semibold">Check Size:</h3>
                                                        </div>
                                                        <div className="flex gap-1 text-base px-2 py-1 rounded-md bg-black cursor-pointer w-fit">
                                                            {fund?.check_size && fund?.check_size.split('\n\n')[0]}
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                fund?.sweet_spot &&
                                                    <div className="flex items-center gap-1">
                                                        <div className="flex-1 pl-0.5">
                                                            <h3 className="m-0 font-semibold">Sweet Spot:</h3>
                                                        </div>
                                                        <div className="flex gap-1 text-base px-2 py-1 rounded-md bg-black cursor-pointer w-fit">
                                                            {fund?.sweet_spot && fund?.sweet_spot.split('\n\n')[0]}
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                fund?.vcinvestor_set && fund?.vcinvestor_set.length > 0 &&
                                    <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h2 className="m-0 leading-none">Team at {fund?.name}</h2>
                                            </div>
                                            <div className="flex flex-col text-left gap-4">
                                                {
                                                    fund?.vcinvestor_set.map((value,index) =>
                                                        <div key={`investor-${index}`}>
                                                            <InvestorSimpleCard values={value} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                investments && investments.length > 0 &&
                                    <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h2 className="m-0 leading-none">Investments</h2>
                                            </div>
                                            <div className="flex flex-col text-left gap-4">
                                                <InvestmentTable data={investments}/>
                                            </div>
                                        </div>
                                    </div>
                            }
                            <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                <div className="flex flex-col gap-6">
                                    <div >
                                        <h2 className="m-0 leading-none">More About {fund?.name}</h2>
                                    </div>
                                    <div className="flex flex-col text-left gap-6">
                                        {
                                            fund?.types && fund?.types.length > 0 &&
                                                <div className="flex flex-col gap-1">
                                                    <div className="pl-0.5">
                                                        <h3 className="m-0 font-semibold">Type:</h3>
                                                    </div>
                                                    <div className="flex flex-wrap gap-2">
                                                        {
                                                            fund?.types.map((value,index) =>
                                                                <div key={`type-${index}`} className="text-sm px-2.5 py-1 rounded-md bg-black cursor-pointer hover:font-semibold">
                                                                    {value.name}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                        }
                                        {
                                            fund?.stages && fund?.stages.length > 0 &&
                                                <div className="flex flex-col gap-1">
                                                    <div className="pl-0.5">
                                                        <h3 className="m-0 font-semibold">Stages:</h3>
                                                    </div>
                                                    <div className="flex flex-wrap gap-2">
                                                        {
                                                            fund?.stages.map((value,index) =>
                                                                <div key={`stage-${index}`} className="text-sm px-2.5 py-1 rounded-md bg-black cursor-pointer hover:font-semibold">
                                                                    {value.name}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                        }
                                        {
                                            fund?.industries && fund?.industries.length > 0 &&
                                                <div className="flex flex-col gap-1">
                                                    <div className="pl-0.5">
                                                        <h3 className="m-0 font-semibold">Areas of Interest:</h3>
                                                    </div>
                                                    <div className="flex flex-wrap gap-2">
                                                        {
                                                            fund?.industries.map((value,index) =>
                                                                <div key={`industry-${index}`} className="text-sm px-2 py-1 rounded-md bg-black cursor-pointer hover:font-semibold">
                                                                    {value.name}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                        }
                                        <div>
                                            <div className="pl-0.5 mb-2">
                                                <h3 className="m-0 font-semibold">Investor Insights:</h3>
                                            </div>
                                            <div className="blur-sm text-white">
                                                <div className="text-lg">
                                                    This is a New Features.<br/>
                                                    For those of you who can read this. We are offering exclusive access to our datasets and insights.
                                                    Email us at team@ecalmers.com
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                fund?.investments && fund?.investments.length > 0 &&
                                    <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h2 className="m-0 leading-none">Investments</h2>
                                            </div>
                                            <div className="flex flex-col text-left gap-4">
                                                <InvestmentTable data={fund?.investments}/>
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                fund?.investments && fund?.investments.length > 0 &&
                                    <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h2 className="m-0 leading-none">Analysis</h2>
                                            </div>
                                            <div className="flex flex-col text-left gap-4">
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                fund?.education && fund?.education.length > 0 &&
                                    <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h2 className="m-0 leading-none">Investors</h2>
                                            </div>
                                            <div className="flex flex-col text-left gap-4">
                                                
                                            </div>
                                        </div>
                                    </div>   
                            }
                            <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg hidden lg:block">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h2 className="m-0 leading-none">Share {fund?.name}'s Page</h2>
                                    </div>
                                    <div className="flex flex-col text-left gap-4">
                                        
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-3 lg:col-span-2 w-full lg:m-0 px-4 pb-24">
                        <div className="pt-0 lg:pt-8 relative md:sticky top-0 flex flex-col gap-4 w-full md:w-10/12 lg:w-full m-auto">
                            <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h2 className="m-0 leading-none">VC Match Score</h2>
                                    </div>
                                    <div className="flex flex-col text-left gap-4">
                                        <div className="text-lg md:text-base font-semibold md:font-medium leading-snug">
                                            Is {fund?.name.split(' ')[0]} a good fit for you?
                                        </div>
                                        <div className="w-full md:w-8/12 lg:w-10/12 m-auto aspect-square blur-sm">
                                            <BasicRadarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h2 className="m-0 leading-none">Are You a Founder?</h2>
                                    </div>
                                    <div className="flex flex-col text-left gap-4">
                                        <div className="text-lg md:text-base font-semibold md:font-medium leading-snug">
                                            Add Your Company. Get Custom Insights.
                                        </div>
                                        <div className="w-full lg:w-10/12 m-auto">
                                            <LinkButton icon={<PlusOutlined/>} value={'Join VC Hunt'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0 p-2 bg-[#1B191C] block md:hidden">
                <LinkButton icon={<HeartOutlined />} value={'Save This Fund'}/>
            </div>
        </div>
    )
}

export default FundPage;