import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import InvestorServices from "../utils/InvestorServices";
import { Helmet } from "react-helmet-async";
import Sidebar from "../../../components/Sidebar/Sidebar";
import LinkIconButton from "../../../components/Button/LinkIconButton";
import { EnvironmentOutlined, HeartOutlined, LeftOutlined, MenuFoldOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import InvestmentTable from "../../../components/Table/InvestmentTable";
import ExperienceTable from "../../../components/Table/ExperienceTable";
import EducationTable from "../../../components/Table/EducationTable";
import BasicRadarChart from "../../../components/Chart/RadarChart";
import { useMainContext } from "../../../components/Context/MainContext";
import LinkButton from "../../../components/Button/LinkButton";
import { Skeleton } from "antd";

function InvestorPage() {
    const params= useParams();
    const [searchParams,setSearchParams] = useSearchParams();
    const {setSidebarCollapsed} = useMainContext();
    const [investor,setInvestor] = useState(null);
    const [investments,setInvestments] = useState(null);

    const getInvestor = async () => {
        if(params.key){
            const investorResponse = await InvestorServices.getInvestor({key:params.key});
            setInvestor(investorResponse.data);
            getInvestments(investorResponse.data.id);
        }
    }
    const getInvestments = async (investor_id) => {
        const investmentResponse = await InvestorServices.getInvestorInvestments({investor_id:investor_id});
        setInvestments(investmentResponse.results);
    }

    useEffect(() => {
        window.scrollTo({top:0});
        getInvestor();
    },[params.key]);

    return(
        <div className="bg-black min-h-screen h-full text-left">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{investor?.name ? `${investor?.name} | Investor Profile | VC Hunt` : 'Investor Profile | VC Hunt'}</title>
                <meta property="og:title" content={`${investor?.name} | Investor Profile | VC Hunt`}/>
                <meta name="description" content={`${investor?.name}, ${investor?.role} | Investor Profile | VC Hunt`} />
                <link rel="canonical" href="https://www.vc-hunt.com" />
            </Helmet>
            <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-1 md:gap-4 w-full h-full m-auto relative">
                <div className="pl-8 py-8 static md:sticky top-0 h-screen">
                    <div className="flex flex-col text-white w-full md:w-11/12 h-full m-auto">
                        <div className="fixed top-0 left-0 right-0 bg-black h-20 p-4 shadow-lg flex items-center z-20 md:relative mb-4 lg:mb-8 flex items-center">
                            <div className="flex-1">
                                <Link to='/'>
                                    <h1>VC Hunt</h1>
                                </Link>
                            </div>
                            <div className="block md:hidden text-2xl">
                                <div className="rounded-xl bg-[#1B191C] px-3 aspect-square flex items-center justify-center" onClick={() => setSidebarCollapsed(true)}>
                                    <MenuFoldOutlined />
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block h-full">
                            <Sidebar />
                        </div>
                    </div>
                </div>
                <div className="col-span-1 md:col-span-3 lg:col-span-4 flex items-start grid grid-cols-1 lg:grid-cols-5 text-left">
                    <div className="col-span-1 lg:col-span-3 p-4 md:p-8 h-full flex justify-end">
                        <div className="w-full md:w-11/12 flex flex-col gap-4 pb-0 lg:pb-8">
                            <div>
                                <Link to='/investors'>
                                    <LeftOutlined/> Back
                                </Link>
                            </div>
                            <div className="p-8 rounded-3xl bg-[#1B191C] text-white h-full shadow-lg relative overflow-hidden">
                                <div className="flex flex-col gap-4">
                                    <div className="absolute top-0 left-0 right-0 aspect-[3/1] md:aspect-[4/1] overflow-hidden">
                                        {
                                            investor?.image_url
                                            ? <div className="w-full aspect-[4/1] bg-cover bg-no-repeat bg-center brightness-50 blur-lg" style={{backgroundImage:`url(${investor?.image_url})`}}></div>
                                            : <div className="w-full aspect-[4/1] bg-black brightness-50 blur-lg"></div>
                                        }
                                    </div>
                                    <div className="pt-4 z-10 mb-2">
                                        {
                                            investor
                                            ?   investor?.image_url
                                                ? <div className="w-1/2 md:w-1/4 aspect-square rounded-xl border bg-cover bg-no-repeat bg-center" style={{backgroundImage:`url(${investor?.image_url})`}}></div>
                                                : <div className="w-1/2 md:w-1/4 aspect-square rounded-xl border bg-black flex items-center justify-center font-bold text-5xl" ><div>{investor?.name.split(' ').map(value => value[0]).slice(0,2).join('')}</div></div>
                                            :   <div className="w-1/2 md:w-1/4 aspect-square rounded-xl border overflow-hidden">
                                                    <Skeleton.Input style={{height:'300px'}} active={true} block />
                                                </div>
                                        }
                                    </div>
                                    <div className="flex flex-col text-left gap-2">
                                        <div className="mb-2">
                                            <div className="mb-3">
                                                {
                                                    investor
                                                    ? <h1 className="text-3xl m-0 leading-none font-bold">{investor?.name} {investor?.age && `(${investor?.age})`}</h1>
                                                    : <Skeleton.Input style={{height:'30px'}} active={true} block/>
                                                }
                                            </div>
                                            {
                                                investor
                                                ?   (investor?.role || investor?.company) &&
                                                        <h2 className="text-white font-medium text-lg m-0 leading-none">
                                                            {investor?.role && investor?.role.split('\n\n')[0]} {investor?.role && investor?.company && 'at'} {investor?.company && <Link to={`/fund/${investor?.company?.key}`} className="underline">{investor?.company?.name}</Link>}
                                                        </h2>
                                                : <div className="w-10/12"><Skeleton.Input style={{height:'20px'}} active={true} block/></div>
                                            }
                                        </div>
                                        {
                                            investor
                                            ? investor?.descriptions && investor?.descriptions.length > 0 &&
                                                <div className="w-full leading-snug text-base bg-[#111111] p-2 md:p-4 rounded-md mb-1">
                                                    <div>
                                                        {investor?.descriptions[0]}
                                                    </div>
                                                </div>
                                            :   <div className="w-full flex flex-col gap-1">
                                                    <div className="w-full"><Skeleton.Input style={{height:'20px'}} active={true} block/></div>
                                                    <div className="w-10/12"><Skeleton.Input style={{height:'20px'}} active={true} block/></div>
                                                </div>
                                        }
                                        <div className="p-6 md:p-8 bg-black mb-4 rounded-sm">
                                            <div>
                                                <h3 className="m-0 leading-none text-xl font-bold">Connect with {investor?.name}</h3>
                                            </div>
                                            <div className="pt-4">
                                                <div className="flex grid grid-cols-2 w-full gap-1">
                                                    {
                                                        investor?.links && Object.keys(investor?.links).length > 0 &&
                                                                Object.keys(investor?.links).map((value,index) =>
                                                                    <a href={investor?.links?.[value]} target="_blank" className="hover:text-white hover:scale-105">
                                                                        <div key={`link-${index}`} className="flex items-center text-base cursor-pointer w-fit">
                                                                            <LinkIconButton type={value} url={investor?.links?.[value]} size="base" /> {value}
                                                                        </div>
                                                                    </a>
                                                                )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2 pb-4">
                                            {
                                                investor == null &&
                                                    <div className="flex flex-col gap-2">
                                                        <Skeleton.Input style={{height:'20px'}} active={true} block/>
                                                        <Skeleton.Input style={{height:'20px'}} active={true} block/>
                                                        <Skeleton.Input style={{height:'20px'}} active={true} block/>
                                                    </div>
                                            }
                                            {
                                                investor?.location &&
                                                    <div className="flex items-center gap-1">
                                                        <div className="flex-1 pl-0.5">
                                                            <h3 className="m-0 font-semibold">Location:</h3>
                                                        </div>
                                                        <div className="flex gap-1 text-base px-2 py-1 rounded-md bg-black cursor-pointer w-fit">
                                                            <EnvironmentOutlined/>
                                                            <div>
                                                                {investor?.location?.city && `${investor?.location?.city?.name}, `}{investor?.location?.country?.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                investor?.check_size &&
                                                    <div className="flex items-center gap-1">
                                                        <div className="flex-1 pl-0.5">
                                                            <h3 className="m-0 font-semibold">Check Size:</h3>
                                                        </div>
                                                        <div className="flex gap-1 text-base px-2 py-1 rounded-md bg-black cursor-pointer w-fit">
                                                            {investor?.check_size && investor?.check_size.split('\n\n')[0]}
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                investor?.sweet_spot &&
                                                    <div className="flex items-center gap-1">
                                                        <div className="flex-1 pl-0.5">
                                                            <h3 className="m-0 font-semibold">Sweet Spot:</h3>
                                                        </div>
                                                        <div className="flex gap-1 text-base px-2 py-1 rounded-md bg-black cursor-pointer w-fit">
                                                            {investor?.sweet_spot  && investor?.sweet_spot.split('\n\n')[0]}
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                <div className="flex flex-col gap-6">
                                    <div >
                                        <h2 className="m-0 leading-none">More About {investor?.name.split(' ')[0]}</h2>
                                    </div>
                                    <div className="flex flex-col text-left gap-6">
                                        {
                                            investor?.types && investor?.types.length > 0 &&
                                                <div className="flex flex-col gap-1">
                                                    <div className="pl-0.5">
                                                        <h3 className="m-0 font-semibold">Type:</h3>
                                                    </div>
                                                    <div className="flex flex-wrap gap-2">
                                                        {
                                                            investor?.types.map((value,index) =>
                                                                <div key={`type-${index}`} className="text-sm px-2.5 py-1 rounded-md bg-black cursor-pointer hover:font-semibold">
                                                                    {value.name}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                        }
                                        {
                                            investor?.stages && investor?.stages.length > 0 &&
                                                <div className="flex flex-col gap-1">
                                                    <div className="pl-0.5">
                                                        <h3 className="m-0 font-semibold">Stages:</h3>
                                                    </div>
                                                    <div className="flex flex-wrap gap-2">
                                                        {
                                                            investor?.stages.map((value,index) =>
                                                                <div key={`stage-${index}`} className="text-sm px-2.5 py-1 rounded-md bg-black cursor-pointer hover:font-semibold">
                                                                    {value.name}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                        }
                                        {
                                            investor?.industries && investor?.industries.length > 0 &&
                                                <div className="flex flex-col gap-1">
                                                    <div className="pl-0.5">
                                                        <h3 className="m-0 font-semibold">Areas of Interest:</h3>
                                                    </div>
                                                    <div className="flex flex-wrap gap-2">
                                                        {
                                                            investor?.industries.map((value,index) =>
                                                                <div key={`industry-${index}`} className="text-sm px-2 py-1 rounded-md bg-black cursor-pointer hover:font-semibold">
                                                                    {value.name}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                        }
                                        <div>
                                            <div className="pl-0.5 mb-2">
                                                <h3 className="m-0 font-semibold">Investor Insights:</h3>
                                            </div>
                                            <div className="blur-sm text-white">
                                                <div className="text-lg">
                                                    This is a New Features.<br/>
                                                    For those of you who can read this. We are offering exclusive access to our datasets and insights.
                                                    Email us at team@ecalmers.com
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                investments && investments.length > 0 &&
                                    <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h2 className="m-0 leading-none">Investments</h2>
                                            </div>
                                            <div className="flex flex-col text-left gap-4">
                                                <InvestmentTable data={investments}/>
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                investments && investments.length > 0 &&
                                    <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h2 className="m-0 leading-none">Analysis</h2>
                                            </div>
                                            <div className="flex flex-col text-left gap-4">
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                investor?.experience && investor?.experience.length > 0 &&
                                    <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h2 className="m-0 leading-none">Experience</h2>
                                            </div>
                                            <div className="flex flex-col text-left gap-4">
                                                <ExperienceTable data={investor?.experience} />
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                investor?.education && investor?.education.length > 0 &&
                                    <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h2 className="m-0 leading-none">Education</h2>
                                            </div>
                                            <div className="flex flex-col text-left gap-4">
                                                <EducationTable data={investor?.education} />
                                            </div>
                                        </div>
                                    </div>   
                            }
                            <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg hidden md:block">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h2 className="m-0 leading-none">Share {investor?.name.split(' ')[0]}'s Page</h2>
                                    </div>
                                    <div className="flex flex-col text-left gap-4">
                                        
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <div className="col-span-1 lg:col-span-2 h-full w-full md:w-11/12 px-0 md:px-8 lg:p-0 m-auto pb-24">
                        <div className="p-4 lg:p-0 pt-0 lg:pt-8 relative lg:sticky top-0 flex flex-col gap-4">
                            <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h2 className="m-0 leading-none">Investor Fit Score</h2>
                                    </div>
                                    <div className="flex flex-col text-left gap-4">
                                        <div className="text-lg lg:text-base md:font-semiboldfont-medium leading-snug">
                                            Is {investor?.name.split(' ')[0]} a good investor fit for your company?
                                        </div>
                                        <div className="w-full md:w-10/12 m-auto aspect-square blur-sm">
                                            <BasicRadarChart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-0 pt-0 md:pt-8 relative md:sticky top-0">
                                <div className="p-8 rounded-3xl bg-[#1B191C] text-white shadow-lg">
                                    <div className="flex flex-col gap-4">
                                        <div>
                                            <h2 className="m-0 leading-none">Are You a Founder?</h2>
                                        </div>
                                        <div className="flex flex-col text-left gap-4">
                                            <div className="text-lg font-semibold leading-snug">
                                                Add Your Company.<br/>
                                                Get Custom Insights.
                                            </div>
                                            <div className="w-full">
                                                <LinkButton icon={<PlusOutlined />} value={'Join VC Hunt'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0 p-2 bg-[#1B191C] block lg:hidden">
                <LinkButton icon={<HeartOutlined />} value={'Save This Investor'}/>
            </div>
        </div>
    )

}

export default InvestorPage;