import { EnvironmentOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";


function FundSimpleListCard({values,onSave=false}){

    return(
        <div className="py-2 rounded-2xl bg-[#1B191C] border md:border-0 text-white cursor-pointer h-full shadow-lg">
            <div className="flex items-start md:items-center gap-2 w-full">
                <div>
                    {
                        values?.image_url
                        ? <div className="w-[30px] md:w-[50px] aspect-square rounded-lg border bg-cover bg-no-repeat bg-center" style={{backgroundImage:`url(${values?.image_url})`}}></div>
                        : <div className="w-[30px] md:w-[50px] aspect-square rounded-xl border bg-black flex items-center justify-center font-bold text-2xl" ><div>{values?.name.split(' ').map(value => value[0]).slice(0,2).join('')}</div></div>
                    }
                </div>
                <div className="flex flex-col gap-1.5 w-full">
                    <div className="text-white flex items-center w-full">
                        <div className="flex items-center flex-1">
                            <Link to={`/fund/${values?.key}`} className="w-full">
                                <div className="flex items-center w-fit mb-0.5">
                                    <h3 className="m-auto leading-none font-semibold w-fit">
                                        {values?.name}
                                    </h3>
                                </div>
                                {
                                    values?.hq_location &&
                                        <div className="text-xs w-fit">
                                            <EnvironmentOutlined/> {values?.hq_location?.name.split(',').length > 1 ? values?.hq_location?.name.split(',')[values?.hq_location?.name.split(',').length-1]: values?.hq_location?.name}
                                        </div>
                                }
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FundSimpleListCard;